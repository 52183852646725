import FormAvaliacao from 'src/components/FormAvaliacao/FormAvaliacao.vue'

export default {
  name: 'Klubi',
  components: { FormAvaliacao },
  meta () {
    return {
      title: 'Klubi',
      description: { name: 'description', content: 'Formulário de Simulação Klubi' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-financiamento.jpg' },
      meta: [
        { 'property': 'og:title', 'content': 'Cadastro Klubi' },
        { 'name': 'description', 'content': 'Formulário de Simulação Klubi' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-cadastro.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-cadastro.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'Netcarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  mounted () {
    let b24script = document.createElement('script')
    b24script.setAttribute('data-skip-moving', 'true')
    b24script.setAttribute('data-b24-form', 'inline/277/xdc60w')
    b24script.src = 'https://cdn.bitrix24.com.br/b14928251/crm/form/loader_277.js?' + (Date.now() / 180000 | 0)
    let b24scriptParent = document.getElementById('b24form')
    b24scriptParent.appendChild(b24script)
  }
}
